import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import elementUI from 'element-ui'

// 高德地图
import AmapVue from '@amap/amap-vue';
 
AmapVue.config.version = '2.0'; // 默认2.0，这里可以不修改
AmapVue.config.key = '07ba0dba8f3d54b1d0e72bbea777c1a4';
AmapVue.config.plugins = [
  'AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType',
  'AMap.PolyEditor', 'AMap.CircleEditor', 'AMap.DistrictSearch', 'AMap.CircleMarker', 'AMap.Polyline'
];
Vue.use(AmapVue);
window._AMapSecurityConfig = {
  securityJsCode:'c5274cc67670f727b397dc3b3ff8ff75',
};
Vue.use(elementUI)

new Vue({
  router,
  store,
  el: '#app',
  render: h => h(App)
})
