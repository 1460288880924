import { render, staticRenderFns } from "./mycard.vue?vue&type=template&id=ed67c5bc&scoped=true&"
import script from "./mycard.vue?vue&type=script&lang=js&"
export * from "./mycard.vue?vue&type=script&lang=js&"
import style0 from "./mycard.vue?vue&type=style&index=0&id=ed67c5bc&prod&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ed67c5bc",
  null
  
)

export default component.exports