import request from '@/utils/request'
import qs from 'qs'
// get 请求
export function getUserInfo () {
  return request({
    url: '/system/user/detail',
    method: 'get'
  })
}

// post 参数为json格式
export function postJSON (data) {
  return request({
    url: '/system/user/detail',
    method: 'post',
    data
  })
}

// post 参数为FormData格式
export function postFormData (data) {
  return request({
    url: '/system/user/detail',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export function login (data, usePassword) {
  return request({
    url: `/im/in/${ usePassword ? 'login' : 'reg_tourist' }`,
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export function reg (data) {
  return request({
    url: `/im/in/reg`,
    method: 'post',
    data,
    dataType: 'formData'
  })
}
export function config (data) {
  return request({
    url: `/im/App/config`,
    method: 'post',
    data,
    dataType: 'formData'
  })
}
export function send (data) {
  return request({
    url: `/im/App/getSms`,
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export function fetchUserInfo (data) {
  return request({
    url: '/im/get/base',
    method: 'post',
    data,
    dataType: 'formData'
  })
}


//获取好友列表
export const getFriendList = data => {
  return request({
    url: '/im/get/friendList',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const getChatList = data => {
  return request({
    url: '/im/get/chatList',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const textMsg = data => {
  return request({
    url: '/im/message/textMsg',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const setListTime = data => {
  return request({
    url: '/im/message/setListTime',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const regTourist = data => {
  return request({
    url: '/im/in/reg_tourist',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const upload = data => {
  return request({
    url: '/im/upload/chat',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const getListTime = data => {
  return request({
    url: '/im/message/getListTime',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const chatData = data => {
  return request({
    url: '/im/get/chatData',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const getListId = data => {
  return request({
    url: '/im/message/getListId',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const getMemberPhotos = data => {
  return request({
    url: '/im/vendor/getMemberPhotos',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const getChatDetails = data => {
  return request({
    url: '/im/message/getChatDetails',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const setRemarks = data => {
  return request({
    url: '/im/friend/setRemarks',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const fetchInfo = data => {
  return request({
    url: '/im/get/details',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const chatTop = data => {
  return request({
    url: '/im/message/chatTop',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const msgDisturb = data => {
  return request({
    url: '/im/message/msgDisturb',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

export const deleteChat = data => {
  return request({
    url: '/im/chat/deleteChat',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
export const chgAvatar = data => {
  return request({
    url: '/im/upload/avatar',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
export const resetNickname = data => {
  return request({
    url: '/im/set/nickname',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
export const resetPassword = data => {
  return request({
    url: '/im/set/password',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 收藏
export const saveStore = data => {
  return request({
    url: '/im/vendor/saveStore',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 撤回
export const withdraw = data => {
  return request({
    url: '/im/message/withdraw',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 收藏列表
export const getUserStore = data => {
  return request({
    url: '/im/vendor/getUserStore',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 收藏列表
export const deleteStore = data => {
  return request({
    url: '/im/vendor/deleteStore',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 添加好友列表
export const searchUser = data => {
  return request({
    url: '/im/get/searchUser',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 添加好友列表
export const applyFriend = data => {
  return request({
    url: '/im/get/applyFriend',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 分享名片
export const sendCard = data => {
  return request({
    url: '/im/message/sendCard',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
// 信息里面分享名片
export const sendCardInfo = data => {
  return request({
    url: '/im/message/sendCardInfo',
    method: 'post', 
    data,
    dataType: 'formData'
  })
}

// 发消息
export const textMsgs = data => {
  return request({
    url: '/im/message/textMsgs',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 加好友
export const friendAdd = data => {
  return request({
    url: '/im/action/friendAdd',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 同意
export const friendAddAction = data => {
  return request({
    url: '/im/action/friendAddAction',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 创建群聊
export const addGroup = data => {
  return request({
    url: '/im/message/addGroup',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 群聊加人
export const addChat = data => {
  return request({
    url: 'im/message/addChat',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
// 修改昵称
export const setGroupData = data => {
  return request({
    url: 'im/message/setGroupData',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
// 修改在群里的昵称
export const saveGroupNickName = data => {
  return request({
    url: 'im/vendor/saveGroupNickName',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
// 禁言
export const groupIsMsg = data => {
  return request({
    url: 'im/message/groupIsMsg',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
// 群内禁止添加好友
export const editChange = data => {
  return request({
    url: 'im/vendor/editChange',
    method: 'post',
    data,
    dataType: 'formData'
  })
}

// 
export const getGroupAdmin = data => {
  return request({
    url: 'im/message/getGroupAdmin',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
// 转让群
export const transferQun = data => {
  return request({
    url: 'im/vendor/transferQun',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
//踢出
export const removeChat = data => {
  return request({
    url: 'im/message/removeChat',
    method: 'post',
    data,
    dataType: 'formData'
  })
}
//
export const updataNoReader = data => {
  return request({
    url: 'im/message/updataNoReader',
    method: 'post',
    data,
    dataType: 'formData'
  })
}


